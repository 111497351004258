<template>
  <div>
    <v-dialog
      v-model="showDialogFullScreen"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="(showDialogFullScreen = false), resetGroupInfo()"
    >
      <v-card>
        <v-toolbar dark color="primary" style="z-index: 1">
          <v-btn icon @click="(showDialogFullScreen = false), resetGroupInfo()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <div>
            <p style="margin: 0">{{ $ml.get("group_config") }}</p>
            <v-toolbar-title>
              {{ name }}
            </v-toolbar-title>
          </div>
          <v-spacer></v-spacer>
          <v-btn v-if="isSmallDevices" icon @click.stop="drawer = !drawer">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </v-toolbar>
        <div>
          <v-row no-gutters>
            <v-col cols="auto">
              <v-navigation-drawer
                v-if="showDialogFullScreen"
                v-model="drawer"
                clipped
                :permanent="!isSmallDevices"
                :temporary="isSmallDevices"
                :absolute="isSmallDevices"
                :width="menuWidth"
                style="height: 100%; max-height: 100%"
              >
                <v-list three-line>
                  <v-list-item-group
                    v-model="selectedItem"
                    color="primary"
                    mandatory
                  >
                    <v-list-item
                      v-for="tab in tabs"
                      :disabled="tab.disabled"
                      :key="tab.name"
                    >
                      <v-list-item-icon>
                        <v-icon>{{ tab.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold body-1">
                          {{ $ml.get(tab.name) }}</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          {{ $ml.get(tab.description) }}.</v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-chip small v-if="tab.disabled">
                        <span>
                          {{ $ml.get("coming_soon") }}
                        </span>
                      </v-chip>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-navigation-drawer>
            </v-col>
            <v-col v-if="selectedItem === 0">
              <EditGeneralConfig
                :activationPeriod="activationPeriod"
                :groupInfo="groupInfo"
                :groupName="groupName"
              />
            </v-col>
            <v-col v-if="selectedItem === 1">
              <v-container>
                <v-col>
                  <GroupLoginSession
                    :groupInfo="groupInfo"
                    @groupInfoChange="groupInfo = $event"
                  ></GroupLoginSession>
                  <v-card-actions style="justify-content: center">
                    <v-btn
                      text
                      @click="(showDialogFullScreen = false), resetGroupInfo()"
                    >
                      {{ $ml.get("cancel") }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      :disabled="canNotSaveQtdDays || !validDates"
                      @click="save()"
                      :loading="loadingSelectedGroup"
                    >
                      {{ $ml.get("save") }}
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-container>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showDialog"
      :width="dialogWidth"
      :scrollable="dialogIsScrollable"
      class="rounded-0"
    >
      <v-card class="rounded-0" tile>
        <v-app-bar elevation="0" color="primary" class="px-2" dark height="80">
          <div>
            <div class="ma-0 pa-0 text-body-2" v-if="isEditName">
              {{ `${$ml.get("edit")} ${$ml.get("name").toLowerCase()}` }}
            </div>
            <div class="ma-0 pa-0 text-body-2" v-if="isDisableGroup">
              {{ `${$ml.get("disable")} ${$ml.get("group").toLowerCase()}` }}
            </div>
            <div class="ma-0 pa-0 text-body-2" v-if="isDeleteGroup">
              {{ `${$ml.get("delete")} ${$ml.get("group").toLowerCase()}` }}
            </div>
            <div class="ma-0 pa-0 text-body-2" v-if="isCloneGroup">
              {{ `${$ml.get("clone")} ${$ml.get("group").toLowerCase()}` }}
            </div>
            <div class="ma-0 pa-0 text-body-2" v-if="isActivateGroup">
              {{ $ml.get("activate_shift") }}
            </div>
            <div class="ma-0 pa-0 text-body-2" v-if="isLogoutSettings">
              {{
                `${$ml.get("define")} ${$ml
                  .get("login_logout_access")
                  .toLowerCase()}`
              }}
            </div>

            <div class="ma-0 pa-0 body-2" v-if="isAccessSettings">
              {{ $ml.get("access_settings") }}
            </div>

            <div class="ma-0 pa-0 body-2" v-if="isSpecialDate">
              {{
                `${$ml.get("edit")} ${$ml
                  .get("holidays_and_special_dates")
                  .toLowerCase()}`
              }}
            </div>

            <div class="ma-0 pa-0 text-body-2" v-if="isPassWordPolicySettings">
              {{ `${$ml.get("password_policy")}` }}
            </div>

            <div class="ma-0 pa-0 text-body-2" v-if="isActivationPeriod">
              {{ $ml.get("activation_period") }}
            </div>

            <div
              class="ma-0 pa-0 font-weight-bold text-h6 text-truncate text-wrap d-flex align-center"
              style="max-width: 320px"
            >
              {{ name }}
            </div>

            <div class="ma-0 pa-0 text-body-2" v-if="isForcingLogout">
              {{ $ml.get("request_logout") }}
            </div>

            <div
              class="ma-0 pa-0 text-body-2"
              v-if="isRequestingPasswordChange"
            >
              {{ $ml.get("request_password_change_label") }}
            </div>
          </div>
          <v-spacer />
          <v-btn icon @click="showDialog = false" dark class="ml-auto">
            <v-icon v-text="'mdi-close'" />
          </v-btn>
        </v-app-bar>

        <!-- CORPO: IPs -->
        <v-card-text v-if="isAccessSettings" class="mt-6">
          <section>
            <v-subheader class="subheader-section mx-md-n4">{{
              $ml.get("allowed_ip_addresses")
            }}</v-subheader>

            <p class="pb-0 text-body-1">
              {{ $ml.get("ip_address_info") }}
            </p>

            <p class="mb-4 text-body-1" v-html="$ml.get('combobox_helper')" />

            <v-combobox
              v-model="editIPs"
              multiple
              small-chips
              persistent-placeholder
              :delimiters="[',', ';', ' ']"
              :hint="$ml.get('ip_warning')"
            >
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip v-bind="attrs" :input-value="selected" small>
                  <span class="pr-2"> {{ item }} </span>
                  <v-icon
                    small
                    @click="parent.selectItem(item)"
                    v-text="'mdi-close-circle'"
                  />
                </v-chip>
              </template>
              <template v-slot:message="{ message }">
                <span v-html="message" />
                <strong
                  ><a
                    href="https://conectasuite.com/perguntas-frequentes/conecta-control/endereco-de-ip-conecta-control/"
                    target="_black"
                  >
                    {{ $ml.get("more_link_text") }}</a
                  ></strong
                >
              </template>
            </v-combobox>
          </section>
          <section>
            <v-subheader class="subheader-section mx-md-n4">{{
              $ml.get("restriction_label_by_browser")
            }}</v-subheader>

            <p class="pb-0 text-body-1">
              {{ $ml.get("allowed_browsers_info") }}
            </p>

            <v-autocomplete
              v-model="editBrowsers"
              :disabled="false"
              :items="browsers"
              chips
              item-value="value"
              multiple
              color="blue-grey lighten-2"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  small
                  @click="data.select"
                  @click:close="data.parent.selectItem(data.item)"
                >
                  <v-icon dark right class="mx-1 ml-0" color="primary">
                    {{ data.item.icon }}
                  </v-icon>
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template>
                  <v-icon dark right class="mx-1 ml-0" color="primary">
                    {{ data.item.icon }}
                  </v-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </section>
          <section>
            <v-subheader class="subheader-section mx-md-n4">{{
              $ml.get("allowed_os")
            }}</v-subheader>

            <p class="pb-0 text-body-1">
              {{ $ml.get("allowed_os_info") }}
            </p>

            <v-autocomplete
              v-model="editOs"
              :disabled="false"
              :items="op_sys"
              chips
              item-value="value"
              multiple
              color="blue-grey lighten-2"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  small
                  @click="data.select"
                  @click:close="data.parent.selectItem(data.item)"
                >
                  <v-icon dark right class="mx-1 ml-0" color="primary">
                    {{ data.item.icon }}
                  </v-icon>
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template>
                  <v-icon dark right class="mx-1 ml-0" color="primary">
                    {{ data.item.icon }}
                  </v-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </section>
        </v-card-text>

        <!-- CORPO: DATAS ESPECIAIS -->
        <v-card-text v-if="isSpecialDate" class="mt-5" style="height: 70vh">
          <EditSpecialDates
            :editSpecialDates="editSpecialDates"
            @change="editSpecialDates = $event"
          />
        </v-card-text>

        <!-- CORPO: POLÍTICA DE SENHA -->
        <v-card-text v-if="isPassWordPolicySettings" class="mt-4">
          <p class="pb-6 text-body-1">
            {{ $ml.get("password_policy_info") }}
          </p>

          <p class="text-body-1">
            {{ $ml.get("define_password_policy_group") }}
          </p>

          <v-select
            v-model="groupInfo.days_period_to_expire_password"
            :items="listOfNumberDaysToExpire"
            dense
            outlined
          />

          <div
            v-if="groupInfo.days_period_to_expire_password === 'select'"
            class="font-weight-medium mb-1"
          >
            {{ $ml.get("set_quantity") }}
          </div>
          <div
            v-if="groupInfo.days_period_to_expire_password === 'select'"
            class="mt-4 d-flex align-center"
          >
            <v-text-field
              v-model="inputDays"
              :min="minDaysPolicy"
              :max="maxDaysPolicy"
              :rules="[rules.minIsOne, rules.maxIsyear]"
              dense
              type="number"
              style="max-width: 120px"
              outlined
            />
            <span class="ml-2 text-body-2"> {{ $ml.get("days") }}</span>
          </div>
        </v-card-text>

        <!-- CORPO: CONFIG DE SESSÃO -->
        <v-card-text v-if="isLogoutSettings" class="mt-6">
          <p class="pb-0 text-body-1">
            {{ $ml.get("logout_settings_info") }}
          </p>
          <GroupLoginSession
            :groupInfo="groupInfo"
            @groupInfoChange="groupInfo = $event"
          ></GroupLoginSession>
        </v-card-text>
        <!-- CORPO: PERÍODO DE ATIVAÇÃO-->
        <v-card-text v-if="isActivationPeriod" class="mt-4">
          <p class="pb-6 text-body-1">
            {{ $ml.get("activation_period_description") }}
          </p>
          {{ $ml.get("activation_period_label") }}
          <InputDateRange
            v-if="showDialog"
            :label="`Ex: 01/02/2023 ${$ml.get('until')} 20/03/2023`"
            @change="setDateRange"
            :activationPeriod="activationPeriod"
          />
        </v-card-text>

        <!-- CORPO: DISABLE GROUP -->
        <v-card-text class="mt-4" v-if="isDisableGroup">
          <p>{{ $ml.get("disabled_group_label") }}</p>
          <Alert
            v-if="definedActivationPeriod"
            dense
            type="warning"
            :showAction="false"
          >
            <p v-html="$ml.get('disable_shift_message_in_period')" />
          </Alert>
        </v-card-text>

        <!-- CORPO: ACTIVE GROUP -->
        <v-card-text
          class="mt-4"
          v-if="isActivateGroup && definedActivationPeriod"
        >
          <p v-html="$ml.get('activate_shift_message_in_period')" />
        </v-card-text>

        <!-- CORPO: DELETE GROUP -->
        <v-card-text class="mt-4" v-if="isDeleteGroup">
          <p>{{ $ml.get("delete_group_label") }}</p>
          <p>{{ $ml.get("action_cannot_undo") }}</p>
        </v-card-text>

        <!-- CORPO: EDIT GROUP NAME -->
        <v-card-text class="mt-4" v-if="isEditName">
          <v-form style="width: 100%">
            <v-text-field
              v-model="groupInfo.name"
              :label="$ml.get('name')"
              required
              persistent-placeholder
              style="max-height: 65px"
            >
            </v-text-field>
          </v-form>
        </v-card-text>

        <!-- CORPO: CLONE GROUP -->
        <v-card-text class="mt-4" v-if="isCloneGroup">
          <span v-html="$ml.get('clone_group_label')"></span>
        </v-card-text>

        <!-- CORPO: FORCAR LOGOUT -->
        <v-card-text class="mt-4" v-if="isForcingLogout">
          <p>{{ $ml.get("logout_group") }}</p>
        </v-card-text>

        <!-- CORPO: REQUEST PASSWORD CHANGE -->
        <v-card-text class="mt-4" v-if="isRequestingPasswordChange">
          <p>{{ $ml.get("request_group_password_change") }}</p>
        </v-card-text>

        <v-card-actions class="mx-1 pb-3">
          <v-spacer />
          <v-btn
            color="secondary"
            text
            @click="$emit('close')"
            :disabled="loadingSelectedGroup"
          >
            {{ $ml.get("cancel") }}
          </v-btn>
          <v-btn
            v-if="isDeleteGroup"
            color="primary"
            text
            @click="deleteThisGroup()"
            :loading="loadingSelectedGroup"
          >
            {{ $ml.get("delete") }}
          </v-btn>
          <v-btn
            v-else-if="isDisableGroup"
            color="primary"
            text
            @click="save()"
            :loading="loadingSelectedGroup"
          >
            {{ $ml.get("disable") }}
          </v-btn>
          <v-btn
            v-else-if="isActivateGroup"
            color="primary"
            text
            @click="save()"
            :loading="loadingSelectedGroup"
          >
            {{ $ml.get("activate") }}
          </v-btn>
          <v-btn
            v-else-if="isCloneGroup"
            color="primary"
            text
            @click="cloneThisGroup()"
            :loading="loadingSelectedGroup"
          >
            {{ $ml.get("clone") }}
          </v-btn>
          <v-btn
            v-else-if="isForcingLogout"
            color="primary"
            text
            @click="forceGroupParticipantsLogout()"
            :loading="loadingSelectedGroup"
          >
            {{ $ml.get("confirm") }}
          </v-btn>
          <v-btn
            v-else-if="isRequestingPasswordChange"
            color="primary"
            text
            @click="forceGroupParticipantsToChangePassword()"
            :loading="loadingSelectedGroup"
          >
            {{ $ml.get("confirm") }}
          </v-btn>
          <v-btn
            v-else-if="isActivationPeriod"
            color="primary"
            :disabled="!dates.length"
            text
            @click="save()"
            :loading="loadingSelectedGroup"
          >
            {{ $ml.get("save") }}
          </v-btn>
          <v-btn
            v-else
            color="primary"
            :disabled="canNotSaveQtdDays || !validDates"
            text
            @click="save()"
            :loading="loadingSelectedGroup"
          >
            {{ $ml.get("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import InputDateRange from "@/components/groups/InputDateRange.vue";
import GroupLoginSession from "@/components/groups/GroupLoginSession.vue";
import EditSpecialDates from "@/components/groups/EditSpecialDates.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import EditGeneralConfig from "./EditGeneralConfig.vue";
import { DEFAULT_CLOCK_TIME_PROVIDER } from "@/helpers/variables";

export default {
  name: "EditGroupInfo",
  components: {
    InputDateRange,
    GroupLoginSession,
    EditSpecialDates,
    EditGeneralConfig,
  },
  props: {
    show: { type: Boolean, required: true },
    showConfigDialog: { type: Boolean, default: false },
    groupKey: { type: String, default: "" },
    groupName: { type: String, default: "" },
    daysToExpire: { type: [Number, null], required: null, default: null },
    /* listOfSpecialDates: { type: [Array, Boolean], default: false },
    listOfIPs: { type: [Array, Boolean], default: false }, */
    edit: { type: String, default: "group_name" },
    activationPeriod: { type: Array, default: () => [] },
  },
  data() {
    return {
      browsers: [
        {
          name: "Firefox",
          icon: "mdi-firefox",
          value: "firefox",
        },
        {
          name: "Google Chrome",
          icon: "mdi-google-chrome",
          value: "chrome",
        },
        {
          name: "Microsoft Edge",
          icon: "mdi-microsoft-edge",
          value: "edge",
        },
        {
          name: "Safari",
          icon: "mdi-apple-safari",
          value: "safari",
        },
        {
          name: "Opera",
          icon: "mdi-opera",
          value: "opera",
        },
        {
          name: "Outlook",
          icon: "mdi-microsoft-outlook",
          value: "outlook",
        },
      ],
      op_sys: [
        {
          name: "Windows",
          icon: "mdi-microsoft-windows",
          value: "windows",
        },
        {
          name: "Linux",
          icon: "mdi-linux",
          value: "linux",
        },
        {
          name: "Chrome OS",
          icon: "mdi-google-chrome",
          value: "chrome os",
        },
        {
          name: "Mac OS X",
          icon: "mdi-apple",
          value: "mac os x",
        },
        {
          name: "Android",
          icon: "mdi-android",
          value: "android",
        },
        {
          name: "iOS",
          icon: "mdi-apple-ios",
          value: "ios",
        },
      ],
      editBrowsers: [],
      editOs: [],
      minDaysPolicy: 1,
      maxDaysPolicy: 365,
      groupInfo: {
        name: this.groupName,
        days_period_to_expire_password: this.daysToExpire || null,
      },
      editIPs: [],
      editSpecialDates: [],
      listOfNumberDaysToExpire: [
        {
          text: `${this.$ml.get("never")}`,
          value: null,
        },
        {
          text: `7 ${this.$ml.get("days")}`,
          value: 7,
        },
        {
          text: `15 ${this.$ml.get("days")}`,
          value: 15,
        },
        {
          text: `30 ${this.$ml.get("days")}`,
          value: 30,
        },
        {
          text: `90 ${this.$ml.get("days")}`,
          value: 90,
        },
        {
          text: `${this.$ml.get("set_quantity")}`,
          value: "select",
        },
      ],
      inputDays: 1,
      rules: {
        minIsOne: (value) => parseInt(value) >= 1,
        maxIsyear: (value) => parseInt(value) <= 365,
      },
      dates: [],
      startDateIndex: 0,
      endDateIndex: 1,
      drawer: false,
      selectedItem: 2,
      tabs: [
        {
          name: "general_config",
          description: "general_config_description",
          icon: "mdi-cog",
          disabled: false,
        },
        // {
        //   name: "participants",
        //   description: "participants_description",
        //   icon: "mdi-account-multiple",
        //   disabled: true,
        // },
        {
          name: "login_logout_access",
          description: "login_logout_access_description",
          icon: "mdi-account-multiple",
          disabled: false,
        },
        // {
        //   name: "time_settings",
        //   description: "time_settings_description",
        //   icon: "mdi-account-multiple",
        //   disabled: true,
        // },
        // {
        //   name: "passwords",
        //   description: "passwords_description",
        //   icon: "mdi-account-multiple",
        //   disabled: true,
        // },
      ],
    };
  },
  watch: {
    showDialog() {
      if (this.showDialog) {
        this.initData();
      }
    },
    "$route.params"() {
      if (Object.keys(this.selectedGroup).length > 0) {
        this.groupInfo = Object.assign({}, this.selectedGroup);
      }
    },
    selectedGroup() {
      if (Object.keys(this.selectedGroup).length > 0) {
        this.groupInfo = Object.assign({}, this.selectedGroup);
      }
      this.editIPs = this.allowedIPs;
      this.editSpecialDates = this.specialDates;
      this.editBrowsers = this.allowedBrowsers;
      this.editOs = this.allowedOs;
    },
    async isActivateGroup(value) {
      if (value && !this.definedActivationPeriod) {
        await this.save();
      }
    },
    async isRemoveActivationPeriod(value) {
      if (value) {
        await this.save();
      }
    },
  },
  computed: {
    ...mapGetters([
      "selectedGroup",
      "loadingSelectedGroup",
      "specialDates",
      "allowedIPs",
      "allowedBrowsers",
      "allowedOs",
    ]),

    validDates() {
      return this.editSpecialDates.every((item) =>
        /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/.test(item)
      );
    },

    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    showDialogFullScreen: {
      get() {
        return this.showConfigDialog;
      },
      set() {
        this.$emit("close");
      },
    },

    menuWidth() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 400;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        return 300;
      } else {
        return 270;
      }
    },

    isSmallDevices() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    isEditName() {
      return this.edit === "group_name";
    },
    isDeleteGroup() {
      return this.edit === "delete_group";
    },
    isDisableGroup() {
      return this.edit === "disable_group";
    },
    isActivateGroup() {
      return this.edit === "activate_group";
    },
    isCloneGroup() {
      return this.edit === "clone_group";
    },
    isLogoutSettings() {
      return this.edit === "logout_settings";
    },
    isPassWordPolicySettings() {
      return this.edit === "password_policy";
    },
    isSpecialDate() {
      return this.edit === "special_date";
    },
    isAccessSettings() {
      return this.edit === "access_settings";
    },

    isForcingLogout() {
      return this.edit === "request_logout";
    },

    isRequestingPasswordChange() {
      return this.edit === "request_password_change";
    },

    isActivationPeriod() {
      return this.edit === "activation_period";
    },
    isRemoveActivationPeriod() {
      return this.edit === "remove_activation_period";
    },

    definedActivationPeriod() {
      return this.selectedGroup.defined_activation_period;
    },

    canNotSaveQtdDays() {
      return (
        (this.isPassWordPolicySettings &&
          this.groupInfo.days_period_to_expire_password === "select" &&
          this.inputDays < this.minDaysPolicy) ||
        this.inputDays > this.maxDaysPolicy
      );
    },

    name() {
      return this.groupName || this.selectedGroup.name;
    },

    key() {
      return this.groupKey || this.selectedGroup.key;
    },

    dialogWidth() {
      if (
        this.isPassWordPolicySettings ||
        this.isAccessSettings ||
        this.isLogoutSettings ||
        (this.definedActivationPeriod && this.isDisableGroup)
      ) {
        return 600;
      } else if (this.isSpecialDate) {
        return 900;
      } else {
        return 400;
      }
    },

    dialogIsScrollable() {
      return this.isSpecialDate;
    },
  },

  methods: {
    ...mapActions([
      "setGroup",
      "deleteGroup",
      "getGroups",
      "setSnackbarCustomMessage",
      "forceGroupsLogout",
      "requestChangeGroupsPassword",
      "cloneGroup",
    ]),
    ...mapMutations(["setSnackbarKey", "setLoadingSelectedGroup"]),

    setDateRange(dates) {
      this.dates = dates;
    },

    save() {
      const payload = this.getPayload();
      this.setGroup(payload);
      this.$emit("resetCurrentAction");
      this.showDialog = !this.loadingSelectedGroup ? false : true;
    },

    resetGroupInfo() {
      this.groupInfo.name = this.groupName || this.name;
      if (Object.keys(this.selectedGroup).length > 0) {
        this.groupInfo = Object.assign({}, this.selectedGroup);
      }

      this.editBrowsers = this.groupInfo.allowed_browsers;
      this.editOs = this.groupInfo.allowed_os;
    },

    deleteThisGroup() {
      const payload = { rule_groups: [this.key] };
      this.setSnackbarKey(false);
      this.deleteGroup(payload)
        .then(() => {
          this.setLoadingSelectedGroup(false);
          this.showDialog = false;
          this.$router.push("/groups");
          this.setSnackbarCustomMessage({
            message: `${this.$ml.get("the_group")} ${this.name} ${this.$ml.get(
              "has_been_deleted"
            )}`,
          });
        })
        .catch((error) => {
          console.error("deleteGroup(): ", error);
          this.setLoadingSelectedGroup(false);
          this.setSnackbarCustomMessage({
            message: `${this.$ml.get("the_group")} ${this.name} ${this.$ml.get(
              "cannot_be_deleted"
            )}`,
            color: "error",
          });
          this.showDialog = false;
        })
        .finally(() => {
          this.setLoadingSelectedGroup(false);
          this.showDialog = false;
        });
    },

    cloneThisGroup() {
      this.setSnackbarKey(false);
      this.cloneGroup(this.key)
        .then(() => {
          this.setLoadingSelectedGroup(false);
          this.getGroups();
          this.showDialog = false;
          this.setSnackbarCustomMessage({
            message: `${this.$ml.get("the_group")} ${this.name} ${this.$ml.get(
              "was_cloned"
            )}`,
          });
        })
        .catch((error) => {
          console.error("cloneGroup(): ", error);
          this.setLoadingSelectedGroup(false);
          this.setSnackbarCustomMessage({
            message: `${this.$ml.get("the_group")} ${this.name} ${this.$ml.get(
              "could_not_be_cloned"
            )}`,
            color: "error",
          });
          this.showDialog = false;
        });
    },

    getPayload() {
      const data = {
        key: this.key,
      };
      if (this.isEditName) {
        data.payload = { name: this.groupInfo.name };
      } else if (this.isDisableGroup) {
        data.payload = { is_active: false };
      } else if (this.isActivateGroup) {
        data.payload = { is_active: true };
      } else if (this.isLogoutSettings) {
        data.payload = {
          auto_logout: this.groupInfo.auto_logout,
          advanced_auto_logout: this.groupInfo.advanced_auto_logout,
          obeys_ponto_mais_rules: this.groupInfo.obeys_ponto_mais_rules,
          pontomais_login_rule: this.groupInfo.pontomais_login_rule,
          pontomais_auto_logout_rules:
            this.groupInfo.pontomais_auto_logout_rules,
          clock_time_provider:
            this.groupInfo.clock_time_provider || DEFAULT_CLOCK_TIME_PROVIDER,
        };
      } else if (this.isPassWordPolicySettings) {
        data.payload = {
          days_period_to_expire_password:
            this.groupInfo.days_period_to_expire_password !== "select"
              ? this.groupInfo.days_period_to_expire_password
              : this.inputDays,
        };
      } else if (this.isSpecialDate) {
        data.payload = {
          list_special_dates: this.editSpecialDates,
        };
      } else if (this.isAccessSettings) {
        data.payload = {
          list_ip: this.editIPs,
          allowed_browsers: this.editBrowsers,
          allowed_os: this.editOs,
        };
      } else if (this.isActivationPeriod) {
        data.payload = {
          start_of_date_use: this.dates[this.startDateIndex],
          end_of_date_use: this.dates[this.endDateIndex],
        };
      } else if (this.isRemoveActivationPeriod) {
        data.payload = {
          start_of_date_use: null,
          end_of_date_use: null,
        };
      }

      return data;
    },

    async forceGroupParticipantsLogout() {
      await this.forceGroupsLogout([this.groupKey]);
      this.showDialog = !this.loadingSelectedGroup ? false : true;
    },

    async forceGroupParticipantsToChangePassword() {
      await this.requestChangeGroupsPassword([this.groupKey]);
      this.showDialog = !this.loadingSelectedGroup ? false : true;
    },

    initData() {
      if (
        ![null, 7, 15, 30, 90].includes(
          this.groupInfo.days_period_to_expire_password
        )
      ) {
        this.groupInfo.days_period_to_expire_password = "select";
      }
      this.editIPs = this.allowedIPs;
      this.editBrowsers = this.allowedBrowsers;
      this.editOs = this.allowedOs;
      this.editSpecialDates = this.specialDates;
    },
  },
  created() {
    this.initData();
  },
  beforeMount() {
    this.groupInfo.name = this.groupName || this.name;
    if (Object.keys(this.selectedGroup).length > 0) {
      this.groupInfo = Object.assign({}, this.selectedGroup);
    }

    this.editBrowsers = this.groupInfo.allowed_browsers;
    this.editOs = this.groupInfo.allowed_os;
  },
  beforeDestroy() {
    this.groupInfo = {};
  },
};
</script>
