<template>
  <v-container>
    <v-card outlined max-width="1400" class="mx-auto rounded-lg" flat>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="allGroups"
        item-key="key"
        :search="search"
        show-select
        checkbox-color="accent"
        fixed-header
        :sort-by="['name']"
        :loading="loadingGroups"
        @toggle-select-all="getPageSelection"
        :class="`${$vuetify.theme.dark ? 'background' : ''} elevation-0`"
        :style="`${
          $vuetify.theme.dark
            ? 'border-bottom: 1px solid #424242 !important'
            : ''
        }`"
        :items-per-page="itemsPerPage"
        :footer-props="{ itemsPerPageOptions: itemsOptions }"
        :height="tableHeight"
        mobile-breakpoint="0"
      >
        <template v-slot:top>
          <v-row
            v-if="hasSelectedGroups"
            :class="`${
              $vuetify.theme.dark ? 'grey darken-2' : 'orange lighten-5'
            } d-flex align-center px-2 my-0 py-0 ma-0`"
            style="border-radius: 7px 7px 0px 0px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-2"
                  icon
                  @click="clearSelectedGroups()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon v-text="'mdi-close'" />
                </v-btn>
              </template>
              <span class="caption">{{ $ml.get("reset_selection") }}</span>
            </v-tooltip>
            <v-card-title>
              {{ selectedGroupsNumber }}
              {{ $ml.get("groups_selected") }}
            </v-card-title>
            <v-col md="7" sm="12" class="d-flex align-center flex-wrap py-0">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    color="accent"
                    class="text-subtitle-2 text-none font-weight-medium"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $ml.get("more") }}
                    <v-icon v-text="'mdi-menu-down'" class="ml-1" small />
                  </v-btn>
                </template>
                <v-list dense>
                  <span
                    v-for="(item, i) in moreOptions"
                    :key="i"
                    :title="tooltipText(item.tooltip)"
                  >
                    <v-list-item
                      v-show="!item.hide"
                      @click="item.action()"
                      :disabled="item.disabled"
                    >
                      <v-list-item-title>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </span>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>

          <v-row
            v-else
            :class="`${
              $vuetify.breakpoint.xs
                ? 'd-flex flex-column'
                : 'd-flex align-center flex-wrap'
            } mx-2 my-0 py-0`"
          >
            <v-col
              lg="5"
              md="8"
              sm="8"
              xs="12"
              :class="`${
                $vuetify.breakpoint.xs ? '' : 'd-flex align-center'
              } py-0 px-0`"
            >
              <v-card-title>
                {{ $ml.get("groups") }}
              </v-card-title>
              <v-divider vertical class="my-6" v-if="!$vuetify.breakpoint.xs" />
              <v-card-subtitle
                :class="`
              ${
                $vuetify.theme.dark
                  ? 'grey--text text--lighten-5'
                  : 'grey--text text--darken-2'
              }
              text-body-1
            `"
                >{{ $ml.get("show_all_groups") }}
              </v-card-subtitle>
            </v-col>
            <v-col
              lg="7"
              md="4"
              sm="4"
              xs="12"
              :class="`${
                $vuetify.breakpoint.xs ? 'py-4' : ''
              } d-flex align-center py-0 px-0`"
            >
              <v-spacer v-if="!$vuetify.breakpoint.xs" />
              <v-btn
                large
                elevation="0"
                color="accent"
                :text="$vuetify.theme.dark"
                :outlined="$vuetify.theme.dark"
                @click="showCreateGroup = true"
                :block="$vuetify.breakpoint.xs"
                :class="`text-none text-body-1 font-weight-medium`"
              >
                <v-icon v-text="'mdi-plus'" />
                <span class="ml-2"> {{ $ml.get("add_group") }}</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider />
          <v-row
            v-if="allSelectedAreInTheSamePage || allSelected"
            :class="`${
              $vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-3'
            } d-flex align-center justify-center px-2 my-0 py-2 ma-0 text-body-2`"
            :style="$vuetify.breakpoint.mdAndUp ? 'height: 40px' : ''"
          >
            <!-- TEXTO QUE APARECE QUANDO TODOS OS ITEMS DA PÁGINA ESTÃO SELECIONADOS -->
            <div
              v-if="allSelectedAreInTheSamePage && !allSelected"
              class="d-flex align-center text-wrap"
            >
              {{ $ml.get("all_of") }}
              <span class="font-weight-medium mx-1">{{
                selectedGroupsNumber
              }}</span>
              {{ $ml.get("groups").toLowerCase() }}
              {{ $ml.get("page_selected") }}
              <v-btn
                text
                x-small
                color="info darken-2"
                :block="$vuetify.breakpoint.sm"
                class="text-none font-weight-medium text-body-2 px-1 py-0 my-0"
                @click="selected = allGroups.slice()"
              >
                {{ $ml.get("select_all_of") }}
                {{ $ml.get("groups").toLowerCase() }}
              </v-btn>
            </div>
            <!-- TEXTO QUE APARECE QUANDO TODOS OS ITENS DA TABELA ESTÃO SELECIONADOS -->
            <div v-if="allSelected" class="d-flex align-center text-wrap">
              {{ $ml.get("all_of") }}
              <span class="font-weight-medium mx-1">{{
                selectedGroupsNumber
              }}</span>
              {{ $ml.get("groups").toLowerCase() }}
              {{ $ml.get("table_selected") }}
              <v-btn
                text
                x-small
                color="info darken-2"
                class="text-none font-weight-medium text-body-2 px-1 py-0 my-0"
                @click="clearSelectedGroups()"
              >
                {{ $ml.get("clear_selection") }}
              </v-btn>
            </div>
          </v-row>
          <v-divider v-if="allSelectedAreInTheSamePage || allSelected" />
          <v-row style="max-height: 72px" class="px-0">
            <v-text-field
              v-model="search"
              :placeholder="$ml.get('search_group')"
              class="elevation-0 mx-1 mt-4 mb-0 transparent"
              background-color="transparent"
              filled
              dense
              rounded
              height="25"
              full-width
              persistent-placeholder
            >
              <template v-slot:prepend-inner>
                <v-icon v-text="'mdi-magnify'" class="my-0 mr-4" left />
              </template>
            </v-text-field>
          </v-row>
          <v-divider />
        </template>
        <template v-slot:loading> {{ $ml.get("loading") }} </template>
        <template v-slot:no-data> {{ $ml.get("no_data") }} </template>
        <template v-slot:no-results> {{ $ml.get("no_results") }} </template>
        <!-- INÍCIO: HEADERS -->
        <template v-slot:[`header.name`]="{ header }">
          {{ $ml.get(header.value) }}
        </template>
        <template v-slot:[`header.is_active`]="{}">
          {{ $ml.get("status") }}
        </template>
        <template v-slot:[`header.users.length`]="{}">
          {{ $ml.get("participants") }}
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <span
            class="blue--text text--darken-2 link"
            @click="pushAndSetSelectedGroup(item.key)"
          >
            {{ item.name }}
          </span>
        </template>
        <template v-slot:[`item.is_active`]="{ item }">
          <span class="d-flex align-center">
            <v-icon
              class="mr-1"
              :color="item.is_active ? 'success' : 'error'"
              size="20"
            >
              {{
                item.is_active
                  ? "mdi-check-circle-outline"
                  : "mdi-close-circle-outline"
              }}
            </v-icon>
            {{ item.is_active ? $ml.get("active") : $ml.get("disabled") }}
          </span>
        </template>
        <template v-slot:[`item.pontomais_login_rule`]="{ item }">
          <span class="d-flex align-center">
            {{ item.pontomais_login_rule ? `${$ml.get("integration")}` : "-" }}
          </span>
        </template>
        <template v-slot:[`item.auto_logout`]="{ item }">
          <span class="d-flex align-center">
            {{ getLogoutText(item) }}
          </span>
        </template>
        <template v-slot:[`item.days_period_to_expire_password`]="{ item }">
          <v-tooltip top max-width="300">
            <template v-slot:activator="{ on: tooltip }">
              <span
                v-if="item.days_period_to_expire_password"
                class="d-flex align-center"
                v-on="{ ...tooltip }"
              >
                {{
                  item.days_period_to_expire_password
                    ? `${$ml.get("days_to_change_password")[0]} ${
                        item.days_period_to_expire_password
                      } ${$ml.get("days_to_change_password")[1]}`
                    : "-"
                }}
              </span>
              <span v-else class="d-flex align-center">-</span>
            </template>
            <span
              >{{ $ml.get("next_group_password_change") }}
              {{ formatDateUTC(item.date_to_expire_password) }}</span
            >
          </v-tooltip>
        </template>
        <template v-slot:[`header.days_period_to_expire_password`]="{}">
          {{ $ml.get("password_policy") }}
          <v-tooltip top max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="grey darken-1"
                v-bind="attrs"
                v-on="on"
                small
                v-text="'mdi-help-circle'"
              />
            </template>
            <span v-text="$ml.get('password_policy_info_shifts')" />
          </v-tooltip>
        </template>
        <template v-slot:[`header.pontomais_login_rule`]="{}">
          {{ $ml.get("login_shifts") }}
          <v-tooltip top max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="grey darken-1"
                v-bind="attrs"
                v-on="on"
                small
                v-text="'mdi-help-circle'"
              />
            </template>
            <span v-text="$ml.get('login_label')" />
          </v-tooltip>
        </template>
        <template v-slot:[`header.auto_logout`]="{}">
          {{ $ml.get("session_termination") }}
          <v-tooltip top max-width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="grey darken-1"
                v-bind="attrs"
                v-on="on"
                small
                v-text="'mdi-help-circle'"
              />
            </template>
            <span v-text="$ml.get('session_termination_label')" />
          </v-tooltip>
        </template>
        <template v-slot:[`item.more`]="{ item }">
          <v-menu offset-y left v-if="!hasSelectedGroups">
            <template v-slot:activator="{ on, attrs }">
              <v-btn right color="grey" icon v-bind="attrs" v-on="on">
                <v-icon v-text="'mdi-dots-vertical'" />
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(option, i) in getGroupActions(item)"
                :key="i"
                link
                class="font-weight-regular"
                @click="execute(item, option)"
                :disabled="option.disabled"
              >
                <v-list-item-title>
                  <span v-for="(key, j) in option.title_keys" :key="j">
                    {{ j == 0 ? $ml.get(key) : $ml.get(key).toLowerCase() }}
                  </span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-snackbar v-model="showSnackbarAllSelected" bottom>
        {{ $ml.get("all_of") }}
        {{ $ml.get("groups").toLowerCase() }}
        {{ $ml.get("table_selected_snackbar") }}
        <template v-slot:action="{ attrs }">
          <v-btn
            dark
            ext
            v-bind="attrs"
            @click="showSnackbarAllSelected = false"
          >
            {{ $ml.get("close") }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
    <CreateGroup :show="showCreateGroup" @close="showCreateGroup = false" />
    <EditGroupInfo
      :show="showEditGroup"
      :groupKey="groupKey"
      :groupName="groupName"
      :edit="currentAction"
      @close="showEditGroup = false"
      @resetCurrentAction="currentAction = ''"
    />
    <ConfirmationDialog
      :target="actionName"
      :show="showConfirmDialog"
      @close="resetConfirmDialog"
      @ok="okConfirmDialog"
      action-text=""
      :reversible="confirmationReversible"
      :alert="alertMessage"
      :loading="loadingGroups"
    >
    </ConfirmationDialog>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CreateGroup from "@/components/groups/CreateGroup";
import EditGroupInfo from "@/components/groups/EditGroupInfo.vue";
import ConfirmationDialog from "@/components/general/ConfirmationDialog.vue";
import {
  getCurrentWeek,
  paging,
  tableHeight,
  formatDateUTC,
} from "@/helpers/utils";

export default {
  components: { CreateGroup, EditGroupInfo, ConfirmationDialog },
  data() {
    return {
      currentGroups: [],
      groupKey: "",
      groupName: "",
      showSnackbarAllSelected: false,
      showEditGroup: false,
      showCreateGroup: false,
      selected: [],
      filters: [],
      currentFilters: {
        is_active: [],
        weekday: [],
      },
      search: "",
      currentAction: "",
      allItemsInThePage: [],
      itemsPerPage: paging.perPage,
      itemsOptions: paging.All,
      tableHeight,
      showConfirmDialog: false,
      actionDialog: "",
      actionName: "",
      confirmationReversible: false,
      alertMessage: {
        show: false,
        message: "",
        type: "",
      },
    };
  },
  computed: {
    ...mapGetters([
      "groups",
      "loadingGroups",
      "selectedGroup",
      "hasPontoMaisIntegration",
    ]),

    allSelectedAreInTheSamePage() {
      if (this.selected.length > 0) {
        return (
          this.selected.every((item) =>
            this.allItemsInThePage.includes(item)
          ) && this.allItemsInThePage.length === this.selected.length
        );
      } else {
        return false;
      }
    },

    allSelected() {
      return this.allGroups.length === this.selected.length;
    },

    allFilters() {
      return this.filters;
    },

    allGroups() {
      if (this.isFiltering) {
        let groups = this.groups.reduce((acc, group) => {
          if (
            Object.keys(this.currentFilters).map((key) => {
              if (this.verifyFilter(group, key)) {
                return group;
              }
            })
          ) {
            acc.push(group);
          }
          return acc;
        }, []);
        return groups;
      }
      return this.groups;
    },

    groupFilters() {
      return [
        {
          title: "status",
          submenu: {
            title: "status",
            options: [
              {
                name: this.$ml.get("active"),
                value: true,
                key: "is_active",
              },
              {
                name: this.$ml.get("disabled"),
                value: false,
                key: "is_active",
              },
            ],
          },
        },
        {
          title: "day_of_week",
          submenu: {
            title: "day_of_week",
            options: getCurrentWeek(),
          },
        },
      ];
    },

    moreOptions() {
      return [
        {
          text: this.$ml.get("request_password_change"),
          action: this.groupsChangePassword,
          disabled: false,
          tooltip: "",
        },
        {
          text: this.$ml.get("request_logout"),
          action: this.groupsLogout,
          disabled: false,
          tooltip: "",
        },
        {
          text: this.$ml.get("activate_selected_shifts"),
          action: this.activateShifts,
          disabled: this.disabledActivateButton,
          tooltip: "cant_activate_groups",
        },
        {
          text: this.$ml.get("deactivate_selected_shifts"),
          action: this.deactivateShifts,
          disabled: this.disabledDeactivateButton,
          tooltip: "cant_deactivate_groups",
        },
        {
          text: this.$ml.get("delete_selected_shifts"),
          action: this.deleteShiftsConfirm,
          disabled: false,
          tooltip: "",
        },
      ];
    },

    disabledActivateButton() {
      return this.selected.every(({ is_active }) => is_active);
    },

    disabledDeactivateButton() {
      return this.selected.every(({ is_active }) => !is_active);
    },

    selectedGroupsNumber() {
      return this.selected.length;
    },
    hasSelectedGroups() {
      return this.selected.length > 0;
    },
    headers() {
      return [
        {
          align: "start",
          sortable: true,
          value: "name",
          width: 250,
        },
        {
          value: "is_active",
          width: 150,
        },
        {
          value: "users.length",
          width: 130,
        },
        {
          value: "is_admin",
        },
        {
          value: "pontomais_login_rule",
          width: 200,
        },
        {
          value: "auto_logout",
          width: 210,
        },
        {
          value: "days_period_to_expire_password",
          width: 210,
        },
        { text: "", value: "more", sortable: false, width: 30 },
      ];
    },

    isFiltering() {
      return this.filters.length !== 0;
    },
    showNewBanner: {
      get() {
        return this.showNewFeatureBanner;
      },
      set(new_value) {
        this.showNewFeatureBanner = new_value;
        localStorage.setItem("newFeatureBanner", this.showNewFeatureBanner);
      },
    },
  },
  watch: {
    allSelected() {
      if (this.allSelected && !this.isFiltering) {
        this.showSnackbarAllSelected = true;
      } else {
        this.allItemsInThePage = [];
        this.selected = [];
      }
    },
  },
  methods: {
    ...mapActions([
      "getGroups",
      "forceGroupsLogout",
      "requestChangeGroupsPassword",
      "activateOrDeactivateShifts",
      "setSnackbarDefault",
      "deleteGroup",
      "setSnackbarCustomMessage",
      "checkPontoMaisIntegration",
    ]),

    ...mapMutations([
      "setSelectedGroup",
      "setLoadingGroups",
      "setGroups",
      "setSelectedGroupWithKey",
      "setSnackbarKey",
      "setLoadingSelectedGroup",
    ]),

    tooltipText(text) {
      if (text === "cant_deactivate_groups" && this.disabledDeactivateButton) {
        return this.$ml.get(text);
      } else if (
        text === "cant_activate_groups" &&
        this.disabledActivateButton
      ) {
        return this.$ml.get(text);
      } else {
        return "";
      }
    },

    getPageSelection(event) {
      if (event.value) {
        this.allItemsInThePage = event.items;
      }
    },

    applyFilter(filter) {
      this.currentFilters[filter.key].push(filter.result);
    },
    verifyFilter(group, key) {
      return this.currentFilters[key].includes(group[key]);
    },

    execute(group, group_action) {
      const { action } = group_action;
      this.setGroup(group, action);
      if (
        action !== "activate_group" ||
        (action === "activate_group" && group.defined_activation_period)
      ) {
        this.showEditGroup = true;
      }
    },
    pushAndSetSelectedGroup(key) {
      this.setSelectedGroupWithKey(key);
      this.$router.push({ name: "Group", params: { key: key } });
    },

    setGroup(group, action) {
      const { key, name } = group;

      this.currentAction = action;
      this.groupKey = key;
      this.groupName = name;
      if (key !== this.selectedGroup.key) {
        this.setSelectedGroup(group);
      }
    },

    clearSelectedGroups() {
      this.selected = [];
    },

    deleteGroups(selectedShiftKeys) {
      this.setLoadingSelectedGroup(true);
      this.setLoadingGroups(true);
      this.setSnackbarKey(false);
      const payload = { rule_groups: selectedShiftKeys };
      this.deleteGroup(payload)
        .then(() => {
          const messages = {
            group: `${this.$ml.get("the_group")} ${this.$ml.get(
              "has_been_deleted"
            )}`,
            groups: `${this.$ml.get("the_groups")} ${this.$ml.get(
              "groups_have_been_deleted"
            )}`,
          };
          this.setSnackbarCustomMessage({
            message:
              selectedShiftKeys.length == 1 ? messages.group : messages.groups,
          });
        })
        .catch((error) => {
          console.error("deleteGroup(): ", error);
          this.setSnackbarCustomMessage({
            message: `${this.$ml.get("the_groups")} ${this.$ml.get(
              "groups_cannot_be_deleted"
            )}`,
            color: "error",
          });
        })
        .finally(() => {
          this.setLoadingSelectedGroup(false);
          this.setLoadingGroups(false);
          this.showDialog = false;
          this.resetAlertMessage();
        });
    },

    resetConfirmDialog() {
      this.showConfirmDialog = false;
      this.resetAlertMessage();
    },

    resetAlertMessage() {
      this.alertMessage = {
        show: false,
        message: "",
        type: "",
      };
    },

    okConfirmDialog() {
      if (this.actionDialog === "request_logout") {
        let selectedGroups = this.selected.map(({ key }) => key);
        this.forceGroupsLogout(selectedGroups);
        this.showConfirmDialog = false;
        this.clearSelectedGroups();
      } else if (this.actionDialog === "change_password") {
        let selectedGroups = this.selected.map(({ key }) => key);
        this.requestChangeGroupsPassword(selectedGroups);
        this.showConfirmDialog = false;
        this.clearSelectedGroups();
      } else if (
        ["activate_selected_shifts", "deactivate_selected_shifts"].includes(
          this.actionDialog
        )
      ) {
        const action =
          this.actionDialog === "activate_selected_shifts"
            ? "activate"
            : "deactivate";

        const selectedShiftKeys = this.selected.map(({ key }) => key);
        this.showConfirmDialog = false;

        const payload = {
          action,
          rule_groups: selectedShiftKeys,
        };
        this.activateOrDeactivateShifts(payload);
        this.clearSelectedGroups();
        this.resetAlertMessage();
      } else if (this.actionDialog === "delete_selected_shifts") {
        let selectedGroups = this.selected.map(({ key }) => key);
        this.deleteGroups(selectedGroups);
        this.showConfirmDialog = false;
        this.clearSelectedGroups();
      }
    },

    hasDefinedActivationPeriod() {
      const definedActivationPeriod = this.selected.some(
        ({ defined_activation_period }) => defined_activation_period
      );

      this.alertMessage.show = definedActivationPeriod;
      this.alertMessage.message = this.$ml.get(
        "shift_message_in_period_in_bulk"
      );
      this.alertMessage.type = "warning";
    },

    groupsLogout() {
      this.showConfirmDialog = true;
      this.confirmationReversible = false;
      this.actionDialog = "request_logout";
      this.actionName = this.$ml.get(this.actionDialog);
    },

    groupsChangePassword() {
      this.showConfirmDialog = true;
      this.confirmationReversible = false;
      this.actionDialog = "change_password";
      this.actionName = this.$ml.get("change_password");
    },

    activateShifts() {
      this.hasDefinedActivationPeriod();
      this.showConfirmDialog = true;
      this.confirmationReversible = true;
      this.actionDialog = "activate_selected_shifts";
      this.actionName = this.$ml.get(this.actionDialog);
    },

    deactivateShifts() {
      this.hasDefinedActivationPeriod();
      this.showConfirmDialog = true;
      this.confirmationReversible = true;
      this.actionDialog = "deactivate_selected_shifts";
      this.actionName = this.$ml.get(this.actionDialog);
    },

    deleteShiftsConfirm() {
      this.actionDialog = "delete_selected_shifts";
      this.alertMessage.show = this.selected.some(({ users }) => users.length);
      this.alertMessage.message = this.$ml.get("delete_group_alert");
      this.alertMessage.type = "warning";
      this.confirmationReversible = false;
      this.showConfirmDialog = true;
      this.actionName = this.$ml.get(this.actionDialog);
    },

    selectFilter(filter_option) {
      this.filters.push(filter_option);
      this.currentFilters[filter_option.key].push(filter_option.value);
    },

    removeFilter(filter_option) {
      const index = this.filters.findIndex((filter) => {
        return filter.key === filter_option.key;
      });
      this.filters.splice(index, 1);
    },

    getGroupActions(item) {
      return [
        {
          title_keys: ["rename", "group"],
          action: "group_name",
          show: true,
        },
        /* {
          title_keys: ["manage_participants"],
          action: "manage_participants",
          disabled: true,
          show: true,
        },*/
        {
          title_keys: ["request_password_change"],
          action: "request_password_change",
          disabled: false,
          show: true,
        },
        {
          title_keys: ["request_logout"],
          action: "request_logout",
          disabled: false,
          show: true,
        },
        {
          title_keys: ["disable", "group"],
          action: "disable_group",
          show: item.is_active,
        },
        {
          title_keys: ["activate", "group"],
          action: "activate_group",
          show: !item.is_active,
        },
        {
          title_keys: ["delete", "group"],
          action: "delete_group",
          show: !item.is_active,
        },
        {
          title_keys: ["clone", "group"],
          action: "clone_group",
          show: true,
        },
      ].filter((option) => option.show);
    },
    formatDateUTC,

    getLogoutText(item) {
      if (
        !item.advanced_auto_logout &&
        !item.auto_logout &&
        item.pontomais_auto_logout_rules.length == 0
      ) {
        return "-";
      } else {
        let text = "";
        if (item.auto_logout) {
          text += "Auto Logout";
        }
        if (item.advanced_auto_logout) {
          text += "Advanced Logout";
        }
        if (
          (item.auto_logout || item.advanced_auto_logout) &&
          item.pontomais_auto_logout_rules.length > 0
        ) {
          text += " / VR Gente | PontoMais Logout";
        } else if (item.pontomais_auto_logout_rules.length > 0) {
          text += "VR Gente | PontoMais Logout";
        }
        return text.trim();
      }
    },
  },

  beforeMount() {
    if (this.hasPontoMaisIntegration === undefined) {
      this.checkPontoMaisIntegration();
    }
  },
};
</script>
