<template>
  <div>
    <v-container style="height: 100vh">
      <v-col>
        <v-card-title class="primary--text font-weight-bold text-h5">
          {{ $ml.get("general_config") }}
        </v-card-title>
        <v-card outlined :loading="loadingSelectedGroup">
          <v-col>
            <v-card-title class="font-weight-bold my-3 text-h6">
              {{ $ml.get("group_info") }}
            </v-card-title>
            <v-card-text class="mt-4">
              <v-row>
                <v-col>
                  <p class="caption ma-0">{{ $ml.get("name") }}</p>
                  <div class="d-flex">
                    <p class="font-weight-medium body-1 d-flex align-center">
                      {{ name }}
                    </p>
                    <v-btn
                      class="mx-2"
                      color="primary"
                      outlined
                      x-small
                      @click="showEditName = true"
                    >
                      {{ $ml.get("edit") }}
                      <v-icon dark right> mdi-pencil-outline </v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col>
                  <p class="caption ma-0">{{ $ml.get("activation_period") }}</p>
                  <div class="d-flex">
                    <p
                      v-if="definedActivationPeriod"
                      class="font-weight-medium body-1 d-flex align-center"
                    >
                      <span>
                        {{ $ml.get("from") }}
                        <strong>
                          {{
                            formatDateUTC(selectedGroup.start_of_date_use)
                          }}</strong
                        >
                        {{ $ml.get("until") }}
                        <strong>
                          {{
                            formatDateUTC(selectedGroup.end_of_date_use)
                          }}</strong
                        >
                      </span>
                    </p>
                    <p v-else class="font-weight-medium body-1">
                      {{
                        status
                          ? $ml.get("always_active")
                          : $ml.get("always_inactive")
                      }}
                    </p>
                    <v-btn
                      v-if="definedActivationPeriod"
                      class="ml-2"
                      color="red"
                      x-small
                      outlined
                      dark
                      @click="removeActivationPeriod"
                    >
                      {{ $ml.get("remove") }}
                      <v-icon> mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                      class="mx-2"
                      color="primary"
                      outlined
                      x-small
                      @click="showActivationPeriod = true"
                    >
                      {{ $ml.get("edit") }}
                      <v-icon dark right> mdi-pencil-outline </v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="ml-5">
              <v-switch
                readonly
                :input-value="status"
                inset
                dense
                class="mb-4 login-logout-checkbox mx-md-n1"
                :true-value="true"
                :false-value="false"
                @click="
                  definedActivationPeriod
                    ? (showConfirmDisable = true)
                    : activateDisableGroup()
                "
              >
                <template v-slot:label>
                  <div class="d-flex flex-column">
                    <div
                      :class="`${
                        status
                          ? 'green--text text--lighten-2'
                          : definedActivationPeriod
                          ? 'yellow--text text--darken-3'
                          : 'red--text text--lighten-2'
                      } text-uppercase text-body-1 font-weight-medium`"
                    >
                      {{
                        status
                          ? $ml.get("active")
                          : definedActivationPeriod
                          ? $ml.get("scheduled_activation")
                          : $ml.get("disabled")
                      }}
                    </div>
                    <div class="font-weight-regular text-body-2">
                      {{
                        status
                          ? $ml.get("disabled_group_label")
                          : $ml.get("activate_group_label")
                      }}
                    </div>
                  </div>
                </template>
              </v-switch>
            </v-card-text>
          </v-col>
        </v-card>
        <v-card outlined class="my-5" :loading="loadingSelectedGroup">
          <v-col>
            <v-row class="d-flex align-center ma-1">
              <v-card-title class="font-weight-bold text-h6">
                {{ $ml.get("delete_group_title") }}
              </v-card-title>
              <v-spacer />
              <v-btn
                class="mr-3"
                color="red"
                dark
                @click="showDeleteGroup = true"
              >
                {{ $ml.get("delete") }}</v-btn
              >
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-container>

    <!-- DIALOG EDIÇÃO DE NOME -->
    <v-dialog width="400" v-model="showEditName">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>
            {{ `${$ml.get("edit")} ${$ml.get("name").toLowerCase()}` }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-form style="width: 100%">
            <v-text-field
              v-model="groupInfo.name"
              :label="$ml.get('name')"
              required
              persistent-placeholder
              style="max-height: 65px"
            >
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showEditName = false"
            :loading="loadingSelectedGroup"
          >
            {{ $ml.get("cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="saveNewName"
            :disabled="!groupInfo.name"
            :loading="loadingSelectedGroup"
          >
            {{ $ml.get("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG PERIODO DE ATIVAÇÃO -->
    <v-dialog width="400" v-model="showActivationPeriod">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>
            {{ $ml.get("activation_period") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-4">
          <p class="pb-6 text-body-1">
            {{ $ml.get("activation_period_description") }}
          </p>
          {{ $ml.get("activation_period_label") }}
          <InputDateRange
            :label="`Ex: 01/02/2023 ${$ml.get('until')} 20/03/2023`"
            @change="setDateRange"
            :activationPeriod="activationPeriod"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showActivationPeriod = false"
            :loading="loadingSelectedGroup"
          >
            {{ $ml.get("cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="saveActivationPeriod"
            :disabled="!dates.length"
            :loading="loadingSelectedGroup"
          >
            {{ $ml.get("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG CONFIRMAÇÃO DA DESATIVAÇÃO/ATIVAÇÃO -->
    <v-dialog width="400" v-model="showConfirmDisable">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>
            {{
              status
                ? `${$ml.get("disable")} ${$ml.get("group").toLowerCase()}`
                : `${$ml.get("activate")} ${$ml.get("group").toLowerCase()}`
            }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-4">
          <p
            class="text-body-1"
            v-html="$ml.get('disable_shift_message_in_period')"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showConfirmDisable = false"
            :loading="loadingSelectedGroup"
          >
            {{ $ml.get("cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="(showConfirmDisable = false), activateDisableGroup()"
            :loading="loadingSelectedGroup"
          >
            {{ $ml.get("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG DE EXCLUSÃO -->
    <v-dialog width="600" v-model="showDeleteGroup">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>
            {{ `${$ml.get("delete")} ${$ml.get("group").toLowerCase()}` }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-4 text-body-1">
          <p>{{ $ml.get("delete_group_label") }}</p>
          <strong>{{ $ml.get("action_cannot_undo") }}</strong>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="showDeleteGroup = false"
            :loading="loadingSelectedGroup"
          >
            {{ $ml.get("cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="(showDeleteGroup = false), deleteThisGroup()"
            :loading="loadingSelectedGroup"
          >
            {{ $ml.get("delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import InputDateRange from "@/components/groups/InputDateRange.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { formatDateUTC } from "@/helpers/utils";

export default {
  name: "EditGeneralConfig",
  components: {
    InputDateRange,
  },
  props: {
    activationPeriod: { type: Array, default: () => [] },
    groupInfo: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      dates: [],
      startDateIndex: 0,
      endDateIndex: 1,
      showEditName: false,
      showActivationPeriod: false,
      showConfirmDisable: false,
      showDeleteGroup: false,
    };
  },
  methods: {
    ...mapActions(["setGroup", "deleteGroup", "setSnackbarCustomMessage"]),
    ...mapMutations(["setSnackbarKey", "setLoadingSelectedGroup"]),

    formatDateUTC,

    setDateRange(dates) {
      this.dates = dates;
    },

    saveNewName() {
      const data = {
        key: this.key,
        payload: { name: this.groupInfo.name },
      };

      this.setGroup(data);
      this.showEditName = false;
    },

    saveActivationPeriod() {
      const data = {
        key: this.key,
        payload: {
          start_of_date_use: this.dates[this.startDateIndex],
          end_of_date_use: this.dates[this.endDateIndex],
        },
      };
      this.setGroup(data);
      this.showActivationPeriod = false;
    },

    removeActivationPeriod() {
      const data = {
        key: this.key,
        payload: {
          start_of_date_use: null,
          end_of_date_use: null,
        },
      };
      this.setGroup(data);
    },

    activateDisableGroup() {
      const data = {
        key: this.key,
        payload: { is_active: !this.status },
      };
      this.setGroup(data);
    },

    deleteThisGroup() {
      const payload = { rule_groups: [this.key] };
      this.setSnackbarKey(false);
      this.deleteGroup(payload)
        .then(() => {
          this.$router.push("/groups");
          this.setSnackbarCustomMessage({
            message: `${this.$ml.get("the_group")} ${this.name} ${this.$ml.get(
              "has_been_deleted"
            )}`,
          });
        })
        .catch((error) => {
          console.error("deleteGroup(): ", error);
          this.setSnackbarCustomMessage({
            message: `${this.$ml.get("the_group")} ${this.name} ${this.$ml.get(
              "cannot_be_deleted"
            )}`,
            color: "error",
          });
        })
        .finally(() => {
          this.setLoadingSelectedGroup(false);
        });
    },
  },

  computed: {
    ...mapGetters(["selectedGroup", "loadingSelectedGroup"]),

    status() {
      return this.selectedGroup.is_active;
    },

    definedActivationPeriod() {
      return this.selectedGroup.defined_activation_period;
    },

    key() {
      return this.selectedGroup.key;
    },

    name() {
      return this.selectedGroup.name;
    },
  },
};
</script>
