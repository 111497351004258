<template>
  <div class="d-flex justify-space-between">
    <v-switch
      inset
      :input-value="value"
      @change="$emit('change', $event)"
      dense
      class="mb-4 login-logout-checkbox mx-md-n1"
      :true-value="true"
      :false-value="false"
      :disabled="disabled"
    >
      <template v-slot:label>
        <div class="d-flex flex-column">
          <div class="font-weight-medium text-body-1">
            {{ message }}
            <v-tooltip bottom v-if="tooltipInfo">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-1"
                  size="20"
                  v-bind="attrs"
                  v-on="on"
                  color="gray"
                  v-text="'mdi-alert-circle-outline'"
                />
              </template>
              <span>{{ tooltipInfo }} </span>
            </v-tooltip>
          </div>
          <div class="font-weight-regular text-body-2">
            {{ description }}
            <a
              v-if="moreInfoLink && moreInfoLink.link"
              target="_blank"
              :href="moreInfoLink.link"
              @click="$event.stopPropagation()"
              >{{ moreInfoLink.text }}</a
            >
          </div>
        </div>
      </template>
    </v-switch>

    <v-chip
      v-show="recommended"
      small
      color="primary"
      class="font-weight-medium"
      >{{ $ml.get("recommended") }}</v-chip
    >
  </div>
</template>

<script>
export default {
  name: "LoginLogoutSwitch",
  props: {
    value: null,
    message: { type: String, default: "" },
    description: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    moreInfoLink: { type: Object, default: null },
    tooltipInfo: { type: String, default: "" },
    recommended: { type: Boolean, default: false },
  },
};
</script>
