<template>
  <v-dialog v-model="showDialog" width="600" class="rounded-0">
    <v-card class="rounded-0" tile>
      <v-app-bar elevation="0" color="primary" class="px-2" dark height="80">
        <div
          class="ma-0 pa-0 font-weight-bold text-h6 text-truncate text-wrap"
          style="max-width: 320px"
        >
          {{ `${$ml.get("create")} ${$ml.get("group").toLowerCase()}` }}
        </div>
        <v-spacer />
        <v-btn icon @click="showDialog = false" dark class="ml-auto">
          <v-icon v-text="'mdi-close'" />
        </v-btn>
      </v-app-bar>

      <v-card-text class="mt-4">
        <v-form style="width: 100%">
          <v-text-field
            v-model="groupInfo.name"
            :label="$ml.get('name')"
            required
            persistent-placeholder
            style="max-height: 65px"
          >
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="mx-1 pb-3">
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="$emit('close')"
          :loading="loadingCreateGroup"
        >
          {{ $ml.get("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="canNotCreateGroup"
          @click="createNewGroup()"
          :loading="loadingCreateGroup"
        >
          {{ $ml.get("create") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { DEFAULT_CLOCK_TIME_PROVIDER } from "@/helpers/variables";

export default {
  name: "CreateGroup",
  props: {
    show: { type: Boolean, required: true },
  },
  data() {
    return {
      groupInfo: {
        name: "",
        list_ip: [],
        auto_logout: false,
        advanced_auto_logout: false,
        pontomais_login_rule: false,
        pontomais_auto_logout_rules: [],
        clock_time_provider: DEFAULT_CLOCK_TIME_PROVIDER,
      },
      default: {
        name: "",
        list_ip: [],
        auto_logout: false,
        advanced_auto_logout: false,
        pontomais_login_rule: false,
        pontomais_auto_logout_rules: [],
        clock_time_provider: DEFAULT_CLOCK_TIME_PROVIDER,
      },
    };
  },
  watch: {
    showDialog() {
      if (!this.showDialog) {
        this.groupInfo = Object.assign({}, this.default);
      }
    },
  },
  computed: {
    ...mapGetters([
      "selectedGroup",
      "loadingSelectedGroup",
      "loadingCreateGroup",
    ]),
    canNotCreateGroup() {
      return !this.groupInfo.name;
    },
    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
  },
  methods: {
    ...mapActions(["createGroup"]),
    async createNewGroup() {
      if (this.groupInfo.list_ip.length < 1) {
        delete this.groupInfo["list_ip"];
      }
      await this.createGroup(this.groupInfo);
      this.showDialog = !this.loadingCreateGroup ? false : true;
    },
    setDateRange(dates) {
      const startDateIndex = 0;
      const endDateIndex = 1;

      if (dates.length) {
        this.groupInfo.start_of_date_use = dates[startDateIndex];
        this.groupInfo.end_of_date_use = dates[endDateIndex];
      }
    },
  },
};
</script>
