<template>
  <div class="card-overlay-container">
    <div class="card-wrapper" v-if="!hasProduct">
      <div class="card-content">
        <div class="overlay-blue"></div>
        <slot></slot>
      </div>
      <div class="top-right">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              style="color: var(--v-info-base)"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
              v-text="'mdi-alert-circle-outline'"
            />
          </template>
          <span>{{ $ml.get(descriptionKey) }}</span>
        </v-tooltip>
      </div>
      <div class="button-container">
        <div class="button-wrapper">
          <v-btn
            elevation="3"
            style="background: var(--v-info-base); color: white"
            large
            @click="goToSelfCheckout"
          >
            <v-icon color="white" class="mr-2" v-text="'mdi-store-plus'" />
            {{ $ml.get("make_plan_upgrade") }}
          </v-btn>
        </div>
      </div>
    </div>
    <div v-else class="card-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "IntegrationInfo",
  props: {
    hasProduct: { type: Boolean, default: true },
    descriptionKey: { type: String, default: "disabled_or_not_found" },
  },
  computed: {
    ...mapGetters("isMicrosoft"),
  },
  methods: {
    ...mapActions(["selfcheckoutRedirect"]),

    goToSelfCheckout() {
      if (this.isMicrosoft) {
        this.$router.push({ path: "/billing/upgrade" });
      } else {
        this.selfcheckoutRedirect();
      }
    },
  },
};
</script>

<style scoped>
.card-overlay-container {
  width: 100%;
  position: relative;
  display: inline-block;
}

.card-wrapper {
  overflow: hidden;
  border-radius: 7px;
  position: relative;
}

.card-content {
  position: relative;
  z-index: 0;
}

.overlay-blue {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(175, 206, 248, 0.253);
  border-radius: 7px;
  border-width: 1px;
  border-color: var(--v-info-base);
  border-style: solid;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.top-right {
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
}

.bottom-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.buy-icon {
  background-color: white;
  color: var(--v-info-base);
}

.button-centered {
  background-color: var(--v-info-base);
}
</style>
