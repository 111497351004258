<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        :label="label"
        single-line
        v-bind="attrs"
        v-on="on"
        style="padding-top: 1px"
      ></v-text-field>
    </template>
    <v-date-picker v-model="dates" :min="today" range class="justify-end">
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        :disabled="!hasChosenDates"
        @click="setDateRange"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
import { formatDateUTC, today } from "@/helpers/utils";

export default {
  name: "InputDate",
  props: {
    label: { type: String, required: true },
    activationPeriod: { type: Array, default: () => [] },
  },

  data() {
    return {
      menu: false,
      inputDates: this.activationPeriod,
      dates: this.activationPeriod,
      today,
      startDateIndex: 0,
      endDateIndex: 1,
    };
  },

  watch: {
    menu(new_value) {
      if (!new_value && this.hasChosenDates) {
        const startDate = this.dates[this.startDateIndex];
        const endDate = this.dates[this.endDateIndex];
        if (new Date(startDate) > new Date(endDate)) {
          this.dates.sort();
        }
      }
    },

    dates() {
      this.inputDates = [...this.dates];
    },
  },

  computed: {
    dateRangeText() {
      if (
        !this.inputDates[this.startDateIndex] ||
        !this.inputDates[this.endDateIndex]
      ) {
        return null;
      }
      return `${formatDateUTC(
        this.inputDates[this.startDateIndex]
      )} ${this.$ml.get("until")} ${formatDateUTC(
        this.inputDates[this.endDateIndex]
      )}`;
    },
    hasChosenDates() {
      return this.dates.length === 2;
    },
  },

  methods: {
    setDateRange() {
      this.menu = false;
      this.$emit("change", this.dates);
    },
  },
};
</script>
