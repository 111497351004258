<template>
  <div>
    <div>
      <v-card-title class="primary--text font-weight-bold text-h5 pl-0">
        {{ $ml.get("logout_config") }}
      </v-card-title>
      <section v-for="(section, i) in sectionsLogoutSettings" :key="i">
        <integration-info
          :hasProduct="section.has_product"
          :descriptionKey="'clock_time_control_unavailable'"
        >
          <v-card outlined class="mb-3">
            <v-col>
              <v-card-title class="font-weight-bold my-3 text-h6">
                {{ $ml.get(section.name) }}
                <v-progress-circular
                  v-if="section.loading"
                  class="mx-2"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-card-title>
              <div
                v-for="(alert, index) in section.alerts"
                :key="`${i}_alert_${index}`"
              >
                <Alert
                  v-if="alert.show && !section.loading"
                  type="info"
                  :prominent="false"
                  dense
                  @action="alert.action"
                  :actionText="$ml.get(alert.text_key)"
                >
                  {{ $ml.get(alert.description_key) }}
                </Alert>
              </div>
              <v-row>
                <v-card-text class="mt-2 ml-5" max-width>
                  <div
                    class="px-2"
                    v-for="(settings, j) in section.settings"
                    :key="`${i}_${j}`"
                  >
                    <div v-if="section.loading">
                      <v-skeleton-loader
                        v-if="settings.switches"
                        type="list-item-two-line"
                      ></v-skeleton-loader>
                      <div v-else-if="settings.list">
                        <v-skeleton-loader
                          type="list-item-two-line"
                        ></v-skeleton-loader>
                        <div class="ml-8">
                          <v-skeleton-loader
                            v-for="(item, k) in settings.items"
                            :key="`${i}_${j}_${k}`"
                            type="list-item-two-line"
                          ></v-skeleton-loader>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <LoginLogoutSwitch
                        v-if="settings.switches"
                        :value="settings.getValue()"
                        @change="settings.change"
                        :message="$ml.get(settings.message_key)"
                        :description="$ml.get(settings.description_key)"
                        :disabled="settings.disabled"
                        :recommended="settings.recomended"
                      />
                      <div v-else-if="settings.list">
                        <LoginLogoutSwitch
                          :value="settings.getValue()"
                          @change="settings.change"
                          :message="$ml.get(settings.message_key)"
                          :description="$ml.get(settings.description_key)"
                          :moreInfoLink="settings.moreInfoLink"
                          :disabled="settings.disabled"
                          :recommended="settings.recomended"
                        />
                        <div class="ml-8">
                          <LoginLogoutSwitch
                            v-for="(item, k) in settings.items"
                            :key="`${i}_${j}_${k}`"
                            :value="item.getValue()"
                            @change="item.change"
                            :message="$ml.get(item.message_key)"
                            :description="$ml.get(item.description_key)"
                            :disabled="item.disabled"
                            :tooltipInfo="$ml.get(item.description_info_key)"
                            :recommended="settings.recomended"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-row>
            </v-col>
          </v-card>
        </integration-info>
      </section>
    </div>

    <div>
      <v-card-title class="primary--text font-weight-bold text-h5 pl-0">
        {{ $ml.get("clocking_session_control_title") }}
      </v-card-title>

      <integration-info
        :hasProduct="hasPontoMaisIntegration"
        :descriptionKey="'clock_time_control_unavailable'"
      >
        <v-card outlined class="mb-3">
          <div
            v-for="(alert, index) in sectionsClockingAlerts"
            :key="`alert_${index}`"
          >
            <Alert
              v-if="alert.show && !loading"
              type="info"
              :prominent="false"
              dense
              @action="alert.action"
              :actionText="$ml.get(alert.text_key)"
              :loading="loading"
            >
              {{ $ml.get(alert.description_key) }}
            </Alert>
          </div>

          <v-autocomplete
            v-model="selectedProvider"
            :items="clock_time_providers"
            item-text="item"
            item-value="value"
            outlined
            dense
            :label="$ml.get('clock_provider_label')"
            @change="setClockTimeProvider"
            class="pt-5 ms-7 me-10"
          ></v-autocomplete>

          <section
            v-for="(section, i) in sectionsClockingLoginSettings"
            :key="`clocking-login-${i}`"
          >
            <v-col>
              <v-card-title class="font-weight-bold my-3 text-h6">
                {{ $ml.get(section.name) }}
                <v-progress-circular
                  v-if="section.loading"
                  class="mx-2"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-card-title>
              <v-row>
                <v-card-text class="ml-5" max-width>
                  <div
                    class="px-2"
                    v-for="(settings, j) in section.settings"
                    :key="`${i}_${j}`"
                  >
                    <div v-if="section.loading">
                      <v-skeleton-loader
                        v-if="settings.switches"
                        type="list-item-two-line"
                      ></v-skeleton-loader>
                      <div v-else-if="settings.list">
                        <v-skeleton-loader
                          type="list-item-two-line"
                        ></v-skeleton-loader>
                        <div class="ml-8">
                          <v-skeleton-loader
                            v-for="(item, k) in settings.items"
                            :key="`${i}_${j}_${k}`"
                            type="list-item-two-line"
                          ></v-skeleton-loader>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <LoginLogoutSwitch
                        v-if="settings.switches"
                        :value="settings.getValue()"
                        @change="settings.change"
                        :message="$ml.get(settings.message_key)"
                        :description="$ml.get(settings.description_key)"
                        :disabled="settings.disabled"
                      />
                      <div v-else-if="settings.list">
                        <LoginLogoutSwitch
                          :value="settings.getValue()"
                          @change="settings.change"
                          :message="$ml.get(settings.message_key)"
                          :description="$ml.get(settings.description_key)"
                          :moreInfoLink="settings.moreInfoLink"
                          :disabled="settings.disabled"
                        />
                        <div class="ml-8">
                          <LoginLogoutSwitch
                            v-for="(item, k) in settings.items"
                            :key="`${i}_${j}_${k}`"
                            :value="item.getValue()"
                            @change="item.change"
                            :message="$ml.get(item.message_key)"
                            :description="$ml.get(item.description_key)"
                            :disabled="item.disabled && !item.getValue()"
                            :tooltipInfo="$ml.get(item.description_info_key)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-row>
            </v-col>
          </section>

          <section
            v-for="(section, i) in sectionsClockingLogoutSettings"
            :key="`clocking-logout-${i}`"
          >
            <v-col>
              <v-card-title class="font-weight-bold my-3 text-h6">
                {{ $ml.get(section.name) }}
                <v-progress-circular
                  v-if="section.loading"
                  class="mx-2"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-card-title>
              <v-row>
                <v-card-text class="ml-5" max-width>
                  <div
                    class="px-2"
                    v-for="(settings, j) in section.settings"
                    :key="`${i}_${j}`"
                  >
                    <div v-if="section.loading">
                      <v-skeleton-loader
                        v-if="settings.switches"
                        type="list-item-two-line"
                      ></v-skeleton-loader>
                      <div v-else-if="settings.list">
                        <v-skeleton-loader
                          type="list-item-two-line"
                        ></v-skeleton-loader>
                        <div class="ml-8">
                          <v-skeleton-loader
                            v-for="(item, k) in settings.items"
                            :key="`${i}_${j}_${k}`"
                            type="list-item-two-line"
                          ></v-skeleton-loader>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <LoginLogoutSwitch
                        v-if="settings.switches"
                        :value="settings.getValue()"
                        @change="settings.change"
                        :message="$ml.get(settings.message_key)"
                        :description="$ml.get(settings.description_key)"
                        :disabled="settings.disabled"
                      />
                      <div v-else-if="settings.list">
                        <LoginLogoutSwitch
                          :value="settings.getValue()"
                          @change="settings.change"
                          :message="$ml.get(settings.message_key)"
                          :description="$ml.get(settings.description_key)"
                          :moreInfoLink="settings.moreInfoLink"
                          :disabled="settings.disabled"
                        />
                        <div class="ml-8">
                          <LoginLogoutSwitch
                            v-for="(item, k) in settings.items"
                            :key="`${i}_${j}_${k}`"
                            :value="item.getValue()"
                            @change="item.change"
                            :message="$ml.get(item.message_key)"
                            :description="$ml.get(item.description_key)"
                            :disabled="item.disabled && !item.getValue()"
                            :tooltipInfo="$ml.get(item.description_info_key)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-row>
            </v-col>
          </section>
        </v-card>
      </integration-info>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoginLogoutSwitch from "@/components/groups/LoginLogoutSwitch.vue";
import { billingAddPlan } from "@/helpers/links";
import IntegrationInfo from "@/components/groups/IntegrationInfo.vue";
import {
  VR_GENTE_CLOCK_TIME_PROVIDER,
  SENIOR_CLOCK_TIME_PROVIDER,
  DEFAULT_CLOCK_TIME_PROVIDER,
  TANGERINO_CLOCK_TIME_PROVIDER,
} from "@/helpers/variables";

export default {
  name: "GroupLoginSession",
  components: {
    LoginLogoutSwitch,
    IntegrationInfo,
  },
  props: {
    groupInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    billingAddPlan,
    loading: false,
    clock_time_providers: [
      { item: "VR Gente", value: VR_GENTE_CLOCK_TIME_PROVIDER },
      {
        item: "Marcação de Ponto 2.0 | Senior",
        value: SENIOR_CLOCK_TIME_PROVIDER,
      },
      { item: "Sólides Tangerino", value: TANGERINO_CLOCK_TIME_PROVIDER },
    ],
    selectedProvider: DEFAULT_CLOCK_TIME_PROVIDER,
  }),
  computed: {
    ...mapGetters([
      "hasPontoMaisIntegration",
      "pontoMaisToken",
      "hasSeniorCredentials",
      "company",
      "hasControlPlan",
    ]),

    hasTangerinoToken() {
      return this.company.tangerino_token;
    },

    clocktimeProviderAllowed() {
      if (this.selectedProvider === VR_GENTE_CLOCK_TIME_PROVIDER) {
        return this.hasPontoMaisIntegration && this.pontoMaisToken;
      }

      return this.hasPontoMaisIntegration && this.hasSeniorCredentials;
    },
    sectionsLogoutSettings() {
      const switches = true;

      const automaticLogout = [
        {
          key: "advanced_auto_logout",
          message_key: "advanced_logout_rule",
          description_key: "advanced_logout_label",
          recomended: true,
          switches,
          getValue: () => {
            return this.groupInfo["advanced_auto_logout"];
          },
          change: (event) => {
            let newGroupInfo = {
              ...this.groupInfo,
              advanced_auto_logout: event,
            };
            if (event && newGroupInfo.auto_logout) {
              newGroupInfo.auto_logout = false;
            }
            if (!event && newGroupInfo.obeys_ponto_mais_rules) {
              newGroupInfo.obeys_ponto_mais_rules = false;
            }
            this.$emit("groupInfoChange", newGroupInfo);
          },
        },
        {
          key: "auto_logout",
          message_key: "logout_rule",
          description_key: "auto_logout_label",
          switches,
          getValue: () => {
            return this.groupInfo["auto_logout"];
          },
          change: (event) => {
            let newGroupInfo = {
              ...this.groupInfo,
              auto_logout: event,
            };
            if (event && newGroupInfo.advanced_auto_logout) {
              newGroupInfo.advanced_auto_logout = false;
              newGroupInfo.obeys_ponto_mais_rules = false;
            }
            this.$emit("groupInfoChange", newGroupInfo);
          },
        },
      ];

      const sections = [
        {
          name: "automatic_logout",
          settings: automaticLogout,
          type: "logout",
        },
      ];
      return this.groupInfo ? sections : [];
    },

    sectionsClockingAlerts() {
      return [
        {
          show:
            this.selectedProvider !== SENIOR_CLOCK_TIME_PROVIDER &&
            !this.pontoMaisToken &&
            this.hasPontoMaisIntegration,
          action: this.goToIntegrations,
          text_key: "see_integrations",
          description_key: "pontomais_token_not_found",
        },
        {
          show:
            this.selectedProvider === SENIOR_CLOCK_TIME_PROVIDER &&
            !this.hasSeniorCredentials &&
            this.hasPontoMaisIntegration,
          action: this.goToIntegrations,
          text_key: "see_integrations",
          description_key: "clock_time_provider_credentials_not_found",
        },
        {
          show:
            this.selectedProvider === TANGERINO_CLOCK_TIME_PROVIDER &&
            !this.hasTangerinoToken &&
            this.hasPontoMaisIntegration,
          action: this.goToIntegrations,
          text_key: "see_integrations",
          description_key: "tangerino_token_not_found",
        },
      ];
    },

    sectionsClockingLogoutSettings() {
      const list = true;

      const integrationPontomaisAutoLogoutItems = [
        {
          message_key: "pontomais_end_auto_logout_label",
          description_key: "pontomais_end_auto_logout_description",
          description_info_key: "pontomais_auto_logout_info",
          disabled:
            this.selectedProvider === SENIOR_CLOCK_TIME_PROVIDER ||
            !this.clocktimeProviderAllowed,
          getValue: () => {
            return this.groupInfo["pontomais_auto_logout_rules"].includes(0);
          },
          change: (event) => {
            const index = 0;
            let values = this.groupInfo["pontomais_auto_logout_rules"] || [];
            if (event) {
              if (!values.includes(index)) {
                values.push(index);
              }
            } else {
              if (values.includes(index)) {
                values.splice(values.indexOf(index), 1);
              }
            }
            const newGroupInfo = {
              ...this.groupInfo,
              pontomais_auto_logout_rules: values,
            };
            if (newGroupInfo.obeys_ponto_mais_rules) {
              newGroupInfo.obeys_ponto_mais_rules = false;
            }
            this.$emit("groupInfoChange", newGroupInfo);
          },
        },
        {
          message_key: "pontomais_break_auto_logout_label",
          description_key: "pontomais_break_auto_logout_description",
          description_info_key: "pontomais_auto_logout_info",
          disabled:
            this.selectedProvider === SENIOR_CLOCK_TIME_PROVIDER ||
            !this.clocktimeProviderAllowed,
          getValue: () => {
            return this.groupInfo["pontomais_auto_logout_rules"].includes(1);
          },
          change: (event) => {
            const index = 1;
            let values = this.groupInfo["pontomais_auto_logout_rules"] || [];
            if (event) {
              if (!values.includes(index)) {
                values.push(index);
              }
            } else {
              if (values.includes(index)) {
                values.splice(values.indexOf(index), 1);
              }
            }
            const newGroupInfo = {
              ...this.groupInfo,
              pontomais_auto_logout_rules: values,
            };
            if (newGroupInfo.obeys_ponto_mais_rules) {
              newGroupInfo.obeys_ponto_mais_rules = false;
            }
            this.$emit("groupInfoChange", newGroupInfo);
          },
        },
      ];

      const integrationWithPontomais = [
        {
          key: "pontomais_auto_logout_rules",
          message_key: "pontomais_auto_logout_label",
          description_key: "pontomais_auto_logout_message",
          disabled: !this.clocktimeProviderAllowed,
          list,
          getValue: () => {
            return this.groupInfo["pontomais_auto_logout_rules"].length > 0;
          },
          change: (event) => {
            let newGroupInfo = {
              ...this.groupInfo,
              pontomais_auto_logout_rules: event
                ? integrationPontomaisAutoLogoutItems.map((item, i) => i)
                : [],
            };
            if (newGroupInfo.obeys_ponto_mais_rules) {
              newGroupInfo.obeys_ponto_mais_rules = false;
            }
            this.$emit("groupInfoChange", newGroupInfo);
          },
          moreInfoLink: {
            text:
              this.selectedProvider === VR_GENTE_CLOCK_TIME_PROVIDER
                ? this.$ml.get("pontomais_link_how_to_config_shift")
                : "",
            link:
              this.selectedProvider === VR_GENTE_CLOCK_TIME_PROVIDER
                ? "https://youtu.be/if5vha3aN3s?list=PLUeoa4K5GfEGwghMFgopuNXn4SFTJbWMv"
                : undefined,
          },
          items: integrationPontomaisAutoLogoutItems,
        },
      ];

      const sections = [
        {
          name: "logout_rules",
          loading: this.loading,
          settings: integrationWithPontomais,
          has_product: this.hasPontoMaisIntegration,
          type: "logout_config",
        },
      ];
      return this.groupInfo ? sections : [];
    },

    sectionsClockingLoginSettings() {
      const switches = true;

      const integrationWithPontomaisLogin = [
        {
          key: "pontomais_login_rule",
          message_key: "pontomais_login_message",
          description_key: "pontomais_login_description",
          disabled:
            !this.clocktimeProviderAllowed &&
            this.selectedProvider !== TANGERINO_CLOCK_TIME_PROVIDER,
          switches,
          getValue: () => {
            if (
              this.groupInfo["advanced_auto_logout"] &&
              this.groupInfo["obeys_ponto_mais_rules"]
            ) {
              return this.groupInfo["obeys_ponto_mais_rules"];
            } else {
              return this.groupInfo["pontomais_login_rule"];
            }
          },
          change: (event) => {
            let newGroupInfo = {
              ...this.groupInfo,
              pontomais_login_rule: event,
            };
            if (newGroupInfo.obeys_ponto_mais_rules) {
              newGroupInfo.obeys_ponto_mais_rules = false;
            }
            this.$emit("groupInfoChange", newGroupInfo);
          },
        },
      ];

      const sections = [
        {
          name: "login_rules",
          loading: this.loading,
          settings: integrationWithPontomaisLogin,
          has_product: this.hasPontoMaisIntegration,
          type: "clocking_login_config",
        },
      ];
      return this.groupInfo ? sections : [];
    },
  },

  methods: {
    ...mapActions(["checkPontoMaisIntegration", "getPontoMaisToken"]),
    openLink() {
      window.open(this.billingAddPlan, "_blank");
    },

    goToIntegrations() {
      this.$router.push("/integrations");
    },

    setClockTimeProvider() {
      this.groupInfo["clock_time_provider"] = this.selectedProvider;
      this.$emit("groupInfoChange", this.groupInfo);
    },
  },

  beforeMount() {
    this.selectedProvider =
      this.groupInfo["clock_time_provider"] || DEFAULT_CLOCK_TIME_PROVIDER;
  },
};
</script>
