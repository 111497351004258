<template>
  <v-stepper vertical flat class="mt-1 mb-0 py-0">
    <v-stepper-step step="1" class="px-4 pt-0 v-stepper__step--active">
      <h3>{{ $ml.get("special_dates_step_1") }}</h3>
    </v-stepper-step>
    <v-stepper-content ref="stepContent1" step="1" class="pt-0 pr-0">
      <v-card-text class="px-2 py-0">
        <p
          class="body-1"
          v-html="$ml.get('special_dates_step_1_description')"
        />
        <v-row justify="center" align="center">
          <v-col
            v-if="!$vuetify.breakpoint.smAndDown"
            align="center"
            class="mb-2 flex-grow-0 flex-shrink-1"
          >
            <v-date-picker
              v-model="databaseDates"
              multiple
              show-adjacent-months
              :events="importedDatesEvents"
              elevation="1"
              class="pb-1"
              width="285"
              style="height: 390px"
            ></v-date-picker>
          </v-col>
          <v-col align="center" class="py-0 flex-grow-1 flex-shrink-0">
            <v-row dense>
              <v-col align="left">
                <h4>
                  {{ $ml.get("special_dates_table_title") }} ({{ thisYear }})
                </h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="left" class="pl-0">
                <v-btn color="primary" outlined @click="selectHolidaysOnly">
                  <v-icon class="mr-1">mdi-calendar-filter-outline</v-icon>
                  {{ $ml.get("special_dates_select_holidays") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-data-table
                  :loading="loading"
                  height="300"
                  fixed-header
                  hide-default-footer
                  style="max-width: 700px"
                  :items-per-page="-1"
                  :value="tableDates"
                  item-key="start.date"
                  show-select
                  :headers="importedHeaders || []"
                  :items="importedDates || []"
                  @item-selected="({ item }) => toggleDate(item)"
                  @toggle-select-all="({ value }) => toggleAllDates(value)"
                >
                  <template v-slot:[`item.start.date`]="{ item }">
                    {{ formatDate(item.start.date) }}
                  </template>
                  <template v-slot:[`item.description`]="{ item }">
                    <v-chip
                      :color="
                        item.description == 'Feriado'
                          ? holidayColor
                          : otherDateColor
                      "
                      class="white--text"
                      >{{ item.description.replace(/\n.*/, "") }}</v-chip
                    >
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-stepper-content>
    <v-stepper-step ref="step2" step="2" class="px-4 v-stepper__step--active">
      <h3>{{ $ml.get("special_dates_step_2") }}</h3>
    </v-stepper-step>
    <v-stepper-content ref="stepContent2" step="2" class="pt-0 pr-0">
      <v-card-text class="px-2 py-0">
        <p
          class="pb-4 mb-0 body-1"
          v-html="$ml.get('special_dates_step_2_description')"
        />
        <v-combobox
          :value="editSpecialDates"
          multiple
          chips
          small-chips
          outlined
          @change="delimit"
          @input="databaseDates = formatAllDatesToDatabase(editSpecialDates)"
          :rules="[rules.date]"
          :delimiters="[',', ';', ' ']"
          deletable-chips
          :label="`${$ml.get('type_paste_or_search')}. ${$ml.get(
            'date_format'
          )}`"
        ></v-combobox>
      </v-card-text>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import { formatDate, formatDateForDatabase } from "@/helpers/utils";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "EditSpecialDates",
  props: {
    editSpecialDates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      databaseDates: [],
      importedHeaders: [
        {
          text: "Data",
          value: "start.date",
          class: "pcell",
          cellClass: "pcell",
        },
        {
          text: "Tipo",
          value: "description",
          align: "center",
          class: "pcell",
          cellClass: "pcell",
        },
        {
          text: "Nome",
          value: "summary",
          class: "pcell",
          cellClass: "pcell",
        },
      ],
      importedDates: [],
      rules: {
        date: (e) =>
          e.every((item) => /^(\d{2})\/(\d{2})\/(\d{4})$/.test(item)) ||
          this.$ml.get("incorrect_dates"),
      },
      steps: [1, 2],
      loading: false,
      holidayColor: "success",
      otherDateColor: "warning",
    };
  },
  computed: {
    ...mapGetters(["specialDates"]),
    tableDates() {
      return this.databaseDates.map((value) =>
        this.importedDates.find((item) => item.start.date == value)
      );
    },
    thisYear() {
      return new Date().getFullYear();
    },
  },
  watch: {
    editSpecialDates() {
      if (this.editSpecialDates.length != this.databaseDates.length) {
        this.databaseDates = this.formatAllDatesToDatabase(
          this.editSpecialDates
        );
        this.$emit(
          "change",
          this.databaseDates.map((date) => this.formatDate(date))
        );
      }
    },
    databaseDates() {
      if (this.editSpecialDates.length != this.databaseDates.length) {
        this.$emit(
          "change",
          this.databaseDates.map((date) => this.formatDate(date))
        );
      }
    },
  },
  methods: {
    ...mapActions(["setSnackbarCustomMessage"]),

    delimit(boxEntries) {
      const reducer = (entries, nextEntry) => [
        ...entries,
        ...nextEntry.split(/[, ;]+/),
      ];
      this.$emit("change", [...new Set(boxEntries.reduce(reducer, []))]);
    },

    formatDate,

    formatDateForDatabase,

    formatAllDatesToDatabase(dates) {
      return dates.map((date) => {
        return formatDateForDatabase(date, "DD/MM/YYYY");
      });
    },

    initData() {
      this.$emit("change", this.specialDates);
      this.databaseDates = this.formatAllDatesToDatabase(this.specialDates);
    },

    stepperCustomStyles() {
      this.$refs.stepContent1.toggle(1);
      this.$refs.stepContent2.toggle(2);
      const step2 = this.$refs.step2;
      step2.$el.classList.remove("v-stepper__step--inactive");
      const step = step2.$el.querySelector(".v-stepper__step__step");
      step.classList.add("primary");
    },

    toggleDate(item) {
      const dateValue = item.start.date;
      if (this.databaseDates.find((value) => dateValue == value)) {
        const index = this.databaseDates.indexOf(dateValue);
        this.databaseDates.splice(index, 1);
      } else {
        this.databaseDates.push(dateValue);
      }
    },

    toggleAllDates(value) {
      for (let item of this.importedDates) {
        const dateValue = item.start.date;
        const found = this.databaseDates.find((value) => dateValue == value);
        if (value && !found) {
          this.databaseDates.push(dateValue);
        } else if (!value && found) {
          this.databaseDates.splice(this.databaseDates.indexOf(dateValue), 1);
        }
      }
    },

    selectHolidaysOnly() {
      this.databaseDates = [];
      for (let item of this.importedDates) {
        const dateValue = item.start.date;
        const databaseDateIndex = this.databaseDates.findIndex(
          (value) => dateValue == value
        );
        if (item.description == "Feriado" && databaseDateIndex < 0) {
          this.databaseDates.push(dateValue);
        }
      }
    },

    getDatesFromCalendar() {
      if (!this.importedDates.length) {
        let url = `${process.env.VUE_APP_API_BASE_URL}/groups/calendar/holidays`;
        this.loading = true;
        this.$axios
          .get(url)
          .then(({ data }) => {
            this.fillImportedDates(data.items);
          })
          .catch((error) => {
            console.error("getDatesFromCalendar(): ", error);
            this.setSnackbarCustomMessage({
              message: `${this.$ml.get("special_dates_get_error")}`,
              color: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    fillImportedDates(items) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const nextYear = currentYear + 1;

      this.importedDates = items.filter((item) => {
        const itemDate = new Date(
          item.start.date.slice(0, 5) + "0" + item.start.date.slice(5, 10)
        );
        const itemYear = itemDate.getFullYear();

        return (
          ((itemYear === currentYear && itemDate >= currentDate) ||
            itemYear === nextYear) &&
          !["Dia do Saci"].includes(item.summary)
        );
      });

      this.importedDates.sort(
        (a, b) => new Date(a.start.date) - new Date(b.start.date)
      );
    },
    importedDatesEvents(value) {
      const importedDate = this.importedDates.find(
        (item) => item.start.date == value
      );
      if (importedDate) {
        if (importedDate.description == "Feriado") {
          return this.holidayColor;
        } else {
          return this.otherDateColor;
        }
      }
      return false;
    },
  },
  created() {
    this.initData();
  },
  mounted() {
    this.stepperCustomStyles();
    this.getDatesFromCalendar();
  },
};
</script>

<style scoped>
.v-stepper__step__step {
  background-color: var(--v-primary-base) !important;
  border-color: var(--v-primary-base) !important;
}
.v-step {
  background-color: var(--v-primary-base) !important;
  border-color: var(--v-primary-base) !important;
}
.v-stepper--vertical .v-stepper__content:not(:last-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-left-width: 1px !important;
}
.v-stepper__content {
  margin-left: 27px !important;
  margin-right: 0px !important;
}
.row {
  margin: 0px;
}
</style>
<style>
.pcell {
  padding: 0 8px !important;
}
</style>
